import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import ReactCardFlip from "@common/ReactCardFlip"
import "./BenefitsListItem.scss"

const BenefitsListItem = ({
  benefit,
  icon,
  cardHeight = "19rem",
  iconFromMarkupPath,
}) => {
  const [isFlipped, setIsFlipped] = useState(false)

  return (
    <div 
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <div
          className="benefits-card"
          isBackside={false}
          style={{
            height: cardHeight,
          }}
        >
          <div className="benefits-card__container" style={{ padding: "7rem 1rem 0" }}>
            {iconFromMarkupPath ? (
              <img
                src={iconFromMarkupPath}
                className="benefits-card__icon"
                alt="Icon"
              />
            ) : (
              <ReactSVG src={icon} className="benefits-card__icon" alt="Icon" />
            )}
            <p className="benefits-card__title">{benefit.title}</p>
          </div>
        </div>
        <div
          className="benefits-card"
          isBackside
          style={{
            height: cardHeight,
            background: "#4A2B43",

          }}
        >
          <div className="benefits-card__container-backside">
            <p className="benefits-card__description">{benefit.description}</p>
          </div>
        </div>
      </ReactCardFlip>
    </div>
  )
}
export default BenefitsListItem
