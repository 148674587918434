import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import arrowDown from "@images/ai/downArrow.svg"
import arrowUp from "@images/ai/upArrow.svg"
import { 
  StyledAccordionSection,
  StyledAccordionTitle,
  StyledAccordion,
  StyledAccordionArrowContainer,
  StyledAccordionContent,
  StyledAccordionText
} from "./styled";

const Accordion = ({ active, setActive, title, content }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");

  function toggleAccordion() {
    setActive(prev => !prev);
    setHeight(active ? "0px" : `${contentRef.current.scrollHeight}px`);
  }

  return (
    <StyledAccordionSection>
      <StyledAccordion
        onClick={toggleAccordion}
      >
        <StyledAccordionTitle>{title}</StyledAccordionTitle>
        <StyledAccordionArrowContainer>{active ? <ReactSVG src={arrowUp} /> : <ReactSVG src={arrowDown} />}</StyledAccordionArrowContainer>
      </StyledAccordion>
      <StyledAccordionContent
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
      >
        <StyledAccordionText
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </StyledAccordionContent>
    </StyledAccordionSection>
  );
}

export default Accordion;
