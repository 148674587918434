import styled from "styled-components"

export const StyledAccordionSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledAccordionTitle = styled.p``;

export const StyledAccordion = styled.div`
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
`;

export const StyledAccordionArrowContainer = styled.span`
    margin-left: 20px;
`;

export const StyledAccordionContent = styled.div`
    background-color: inherit;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.60);
    transition: max-height 0.6s ease;
`;

export const StyledAccordionText = styled.div`
    padding: 18px;
`;