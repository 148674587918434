import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledWhyChooseWrapper = styled.div``;

export const StyledBenefitsListSectionContainer = styled.div`
  & .benefits-section {
    margin-bottom: 0;
  }

  & .benefits-section-items {
    grid-template-rows: none;
  }

  & .benefits-card {
    margin-bottom: 2.5rem;
    height: 19rem;

    &__icon {
      svg {
        width: 5rem !important;
        height: 5rem !important;
      }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      height: fit-content;
    }
  }

  & .benefits-section-items :nth-child(4) {
    margin-bottom: 0;
  }
`;

export const StyledCompanyCooperationProsContainer = styled.div`
  .benefits-section {
    margin-top: 5rem !important;
  }
`;


export const StyledSectionTitle = styled.h2`
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.438rem;
    color: #ffffff;
    
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
    }
`;