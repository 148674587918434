import React, { useState } from "react"
import Accordion from "@common/Accordion"
import {
  StyledWhyChooseItemsContainer,
  StyledWhyChooseItemContainer,
  StyledReactSVG,
  StyledWhyChooseItemDetails,
  StyledWhyChooseItemTitle,
} from "./styled"

const BenefitCard = ({ title, description, iconFromMarkupPath, icon }) => {
  const [active, setActive] = useState(false)

  return (
    <StyledWhyChooseItemContainer active={active}>
      <Accordion
        active={active}
        setActive={setActive}
        title={
          <>
            <StyledWhyChooseItemDetails>
              <StyledReactSVG src={iconFromMarkupPath ?? icon} />
              <StyledWhyChooseItemTitle>{title}</StyledWhyChooseItemTitle>
            </StyledWhyChooseItemDetails>
          </>
        }
        content={description}
      />
    </StyledWhyChooseItemContainer>
  )
}

const BenefitCardsMobile = ({ configuration }) => {
  return (
    <StyledWhyChooseItemsContainer>
      {configuration?.map(props => (
        <BenefitCard {...props} />
      ))}
    </StyledWhyChooseItemsContainer>
  )
}

export default BenefitCardsMobile;
