import styled from "styled-components";

export const BenefitListContainer = styled.div`
    & .benefits-section {
        margin-bottom: 0;
    }

    & .benefits-card {
        margin-bottom: 40px;
    }

    & .extended {
        @media only screen and (max-width: 425px) {
            max-width: 330px;
        }
    }
    & .benefits-section-items :nth-child(4) {
        margin-bottom: 0;
    }
`;
