import React from "react";
import firstBenefit from "@images/benefit1.svg";
import thirdBenefit from "@images/benefit3.svg";
import hat from "@images/hat.svg";
import tax from "@images/tax.svg";
import { LayoutWrapper } from "../../../../styles/CommonStyles";
import BenefitsFlippableListSection from "@common/CommonSections/BenefitsFlippableListSection";

const listItems = [
    {
        title: "Full commitment",
        icon: firstBenefit,
        description: `
            Our developers are dedicated to each project full-time and focus on delivering the best results. 
            All your specific needs and requests are guaranteed to be fulfilled by an outsourced development team. 
            Moreover, if the circumstances change, you are able to make necessary adjustments and continuously
            improve your product. The Impressit team's full commitment also gives you the opportunity to focus
            on other projects and parts of your business.
        `,
    },
    {
        title: "Time & costs savings",
        icon: tax,
        description: `
            By expertly assessing the scope and complexity of the project, we are able to deliver excellent results quickly
            regardless of the project complexity. If you hire dedicated development team, you can be assured that you will
            fit your budget as well as can expect faster time-to-market. Dedicated teams are also flexible so you have an
            opportunity to change the team structure
        `,
    },
    {
        title: "Reduced risks",
        icon: thirdBenefit,
        description: `
            Choosing to hire an outsourced development team with Impressit means choosing carefully selected motivated professionals
            who are ready for a long-time commitment. You still remain in control of your project by setting goals and deadlines, 
            creating acceptance criteria, and monitoring progress. However, you can be sure that with the focused dedicated team by your side, 
            the development of the project is consistent and continuous.
        `,
    },
    {
        title: "Experience",
        icon: hat,
        description: `
            Impressit has experience in developing a wide variety of products and is always ready to find non-conventional
            solutions to complicated tasks. Our brilliant team consists of talented developers and true professionals that
            always deliver their best, are great communicators, and are not afraid of challenges. Outsource dedicated software
            development teams with Impressit if you expect to hire top-notch software development experts.
        `,
    },
];

const WhyChooseSection = () => {
    return (
        <LayoutWrapper>
            <BenefitsFlippableListSection configuration={listItems} />
        </LayoutWrapper>
    );
};

export default WhyChooseSection;
