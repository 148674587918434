import React from "react";
import loadable from "@loadable/component";
import firstBenefit from "@images/benefit1.svg";
import thirdBenefit from "@images/benefit3.svg";
import hat from "@images/hat.svg";
import tax from "@images/tax.svg";
import { BenefitListContainer } from "./WhyChooseSection.style";

const BenefitsFlippableListSection = loadable(() => import("@common/CommonSections/BenefitsFlippableListSection"));

const listItems = [
    {
        title: "Commitment",
        icon: firstBenefit,
        description: `
            Because we are committed to your success, we recommend adopting DevOps 
            practices as it improves communication with and within the development team.
        `,
    },
    {
        title: "Reduced risks",
        icon: tax,
        description: `
            Our DevOps service allows to identify and eliminate risks earlier and respond to changes faster
        `,
    },
    {
        title: "Time & Costs Savings",
        icon: thirdBenefit,
        description: `
            Taking into consideration the two points above, you can see that the team will definitely 
            be more productive while the development process will be more efficient.
        `,
    },
    {
        title: "Experience",
        icon: hat,
        description: `
            Our team of senior DevOps experts will not only do the excellent job on your project but also
            can consult you and answer your questions regarding the software development infrastructure.
        `,
    },
];

const WhyChooseSection = () => (
    <BenefitListContainer>
        <BenefitsFlippableListSection
            configuration={listItems}
            heading="Why choose Impressit?"
        />
    </BenefitListContainer>
);

export default WhyChooseSection;
