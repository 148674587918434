import React from "react";
import "./PageHeading.scss";

const PageHeading = ({
    heading,
    mainParagraph,
    secondParagraph,
    thirdParagraph,
}) => (
    <div className="page-heading">
        <h2 className="page-heading__heading">{heading}</h2>
        {mainParagraph && (
            <p className="page-heading__paragraph">{mainParagraph}</p>
        )}
        {secondParagraph && (
            <div className="page-heading__paragraph-container">
                <p className="page-heading__paragraph">{secondParagraph}</p>
            </div>
        )}
        {thirdParagraph && (
            <div className="page-heading__paragraph-container">
                <p className="page-heading__paragraph">{thirdParagraph}</p>
            </div>
        )}
    </div>
);

PageHeading.defaultProps = {
    heading: "",
    mainParagraph: "",
    secondParagraph: "",
    thirdParagraph: "",
};

export default PageHeading;
