import React from "react"
import useWindowDimensions from "@hooks/useWindowDimensions"
import Wrapper from "./components/desktop"
import { BenefitCardsMobile, BenefitCardsDesktop } from "./components"
import {
  StyledCompanyCooperationProsContainer,
  StyledBenefitsListSectionContainer,
  StyledSectionTitle,
} from "./styled"

const BenefitsFlippableListSection = ({ heading = "Benefits", configuration, cardHeight }) => {
  const { isDesktop } = useWindowDimensions()
  return (
    <>
      {isDesktop ? (
        <StyledCompanyCooperationProsContainer>
          <StyledBenefitsListSectionContainer>
            <BenefitCardsDesktop
              listItems={configuration}
              heading={heading}
              cardHeight={cardHeight}
            />
          </StyledBenefitsListSectionContainer>
        </StyledCompanyCooperationProsContainer>
      ) : 
      (
        <>
         <StyledSectionTitle>{heading}</StyledSectionTitle>
         <BenefitCardsMobile configuration={configuration} />
        </>
      )
      }
    </>
  )
}

export default BenefitsFlippableListSection
