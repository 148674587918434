import React from "react";
import loadable from "@loadable/component";
import firstBenefit from "@images/benefit1.svg";
import thirdBenefit from "@images/benefit3.svg";
import hat from "@images/hat.svg";
import tax from "@images/tax.svg";
import { BenefitListContainer } from "./WhyChooseSection.style";

const BenefitsFlippableListSection = loadable(() =>
    import("@common/CommonSections/BenefitsFlippableListSection")
);

const listItems = [
    {
        title: "Commitment",
        icon: firstBenefit,
        description: `
            Having a distinct product development plan and a strong team by your 
            side gives you the confidence that your project will succeed.
        `,
    },
    {
        title: "Time & Costs Savings",
        icon: tax,
        description: `
            Thorough analysis of the project allows creating the most convenient and efficient plan, 
            which prevents future development delays and unnecessary expenses.
        `,
    },
    {
        title: "Reduced Risks",
        icon: thirdBenefit,
        description: `
            The discovery phase reveals all the hidden potential risks and allows you to circumvent them.
        `,
    },
    {
        title: "Experience",
        icon: hat,
        description: `
            With our discovery phase services, you will learn everything you need to know about your product
            and the process of project development, which will help you to manage it successfully in subsequent stages
        `,
    },
];

const WhyChooseSection = () => (
    <BenefitListContainer>
        <BenefitsFlippableListSection heading="Why choose Impressit?" configuration={listItems} />
    </BenefitListContainer>
);

export default WhyChooseSection;
