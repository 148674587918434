import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"
import { ReactSVG } from "react-svg"

export const StyledWhyChooseWrapper = styled.div``;

export const StyledWhyChooseTitle = styled.h4`
    color: rgba(255, 255, 255, 0.80);
    font-family: "Nunito Sans";
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 128.571%;
    letter-spacing: 0.56px;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      font-size: 24px;
      line-height: 129.167%;
      letter-spacing: 0.48px;
}
`;

export const StyledWhyChooseItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
      grid-template-columns: 1fr;
}
`;

export const StyledWhyChooseItemContainer = styled.div`
    display: flex;
    min-height: 208px;
    padding: 32px;
    background: ${({ isBackside, active }) => !!isBackside || !!active ? "#4A2B43" : "#3A1A3C"};

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
      min-height: 100px;
}

@media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    padding: 12px;
}

@media only screen and (max-width: 330px) {
    padding: 0;
}

`;

export const StyledWhyChooseItemDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;

    rect {
        fill: inherit
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        gap: 20px;
        svg {
         height: 40px;
         width: 40px;
        }
}
`;

export const StyledReactSVG = styled(ReactSVG)``;


export const StyledWhyChooseItemTitle = styled.span`
    color: rgba(255, 255, 255, 0.80);
    font-family: "Nunito Sans";
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 128.571%;
    letter-spacing: 0.56px;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 24px;
        line-height: 129.167%;
        letter-spacing: 0.48px;
}

@media only screen and (max-width: 330px) {
    font-size: 19px;
}
`;

export const StyledWhyChooseItemDescription = styled.span`
    color: rgba(255, 255, 255, 0.60);
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0.8px;
`;
